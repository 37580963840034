import { Controller } from "@hotwired/stimulus"

// https://betterprogramming.pub/show-and-hide-elements-with-rails-7s-stimulus-e988c35fbfb3

/* 
<div id="options" data-controller="visibility">
  <button data-action="visibility#showTargets">Show All Elements</button>
  <button data-action="visibility#hideTargets">Hide All Elements</button>
  <button data-action="visibility#toggleTargets">Toggle Element Visibility</button>
    
  <p data-visibility-target="hideable">Thing #1<p/>
  <p data-visibility-target="hideable" hidden>Thing #2<p/>
</div>
*/

// Currently used for toggling summary visibility on the bill chart and hearings page. To enable this, the card component description can be hideable, and the summary section on BillComponent is hideable. 
export default class extends Controller {
  static get targets() { 
    return [ "hideable" ] 
  }

  showTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = false;
    });
  }

  hideTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = true;
    });
  }
 
  toggleTargets() {
    console.log("toggling");
    this.hideableTargets.forEach((el) => {
      el.hidden = !el.hidden
    });
  }
}
