import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() { 
    return [ "submit" ] 
  }

  submit() {
    this.submitTarget.click()
  }
}
